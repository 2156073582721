$alert-error-background-color: #FFC9CC;
$alert-error-color: #9C000D;

$alert-info-background-color: #B6EEAF;
$alert-info-color: #1D8D0C;

$alert-warn-background-color: #FFEEBA;
$alert-warn-color: #E67219;

$bg-color-accepted: #e6ffed;
$bg-color-container: aliceblue;
$bg-color-header: lightpink;
