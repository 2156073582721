snack-bar-container.xxx-alert-error {
  background-color: $alert-error-background-color;
  color: $alert-error-color;
}

snack-bar-container.xxx-alert-info {
  background-color: $alert-info-background-color;
  color: $alert-info-color;
}

snack-bar-container.xxx-alert-warn {
  background-color: $alert-warn-background-color;
  color: $alert-warn-color;
}
