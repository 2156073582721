/* master scss file, global styles and imports of other app scope scss files */

/* global font */
body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}
* {
  font-family: 'Lato', sans-serif !important; 
  /* Add !important to overwrite all elements */
}

mat-form-field label {
  font-size: 14px !important;
}
.footer-copyright {
  font-size: 14px !important;
}
p {
  font-size: 14px;
}


#consent-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
  display: none;
}

#consent-banner button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
}

#consent-banner button:hover {
  background-color: #357ae8;
}

#consent-banner input[type="checkbox"] {
  margin-right: 5px;
}
#consent-banner label{
  margin-right: 10px;
}

/* angular material */
@import "vendor/angular-material";

/* angular material menu and icon setup */
@import "vendor/mat-icon";

/* responsive css */
@import "partials/media-variables";
@import "partials/media-phones";
@import "partials/media-tablets";
@import "partials/media-pcs";

/* global colors */
@import "partials/colors";

/* for xxx-alert */
@import "partials/alert";

/* You can add global styles to this file, and also import other style files */
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css";
@import "https://fonts.googleapis.com/css?family=Open+Sans";
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


